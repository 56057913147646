@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

blockquote,
q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: "";
  content: none; }

q:before, q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@font-face {
  font-family: "Gotham";
  src: url("fonts/Gotham-Bold.woff2") format("woff2"), url("fonts/Gotham-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "Gotham Book";
  src: url("fonts/Gotham-Book.woff2") format("woff2"), url("fonts/Gotham-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

:root {
  --amazonOrange: #ff2837 !important;
  --font-family: "Gotham Book" !important; }

html,
body {
  font-size: 12px; }
  @media (min-width: 760px) {
    html,
    body {
      font-size: 16px; } }

body {
  font-family: "Gotham Book";
  padding: 4rem 1rem 0rem 1rem; }

header {
  position: fixed;
  height: 3rem;
  padding: 1rem;
  z-index: 10;
  left: 0px;
  top: 0px;
  background: #161632;
  color: #fff;
  width: 100vw;
  box-sizing: border-box; }
  header img {
    width: auto;
    height: 1rem; }
  header button {
    float: right;
    background: transparent;
    cursor: pointer;
    border: 0px;
    font-size: 12px;
    color: #fff;
    text-transform: lowercase; }

section {
  background: #fff;
  border-radius: 5px;
  clear: both;
  position: relative;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  padding: 2rem;
  margin-bottom: 1rem; }
  section:last-of-type {
    margin-bottom: 0px; }
  section h1:first-of-type {
    display: block !important;
    color: #161632;
    font-size: 1.5rem;
    font-weight: normal;
    font-family: "Gotham Book";
    margin: 0px;
    text-transform: uppercase;
    width: 100%;
    position: relative;
    padding-right: 4rem;
    box-sizing: border-box; }
    section h1:first-of-type:after {
      position: absolute;
      content: "⌟";
      position: absolute;
      right: 0rem;
      text-transform: none;
      font-size: 2rem;
      top: 50%;
      transform: translateY(-50%);
      color: #ff2837; }
  section h1:not(:first-of-type),
  section h2,
  section h3,
  section h4,
  section h5 {
    font-size: 1.35rem;
    font-weight: normal;
    letter-spacing: 0.35px;
    margin: 0.5em 0; }
  section img,
  section p,
  section ul,
  section ol {
    margin: 0.5rem 0; }
  section ul,
  section ol {
    font-size: 1rem;
    line-height: 1.5rem; }
  section h1 + h1 {
    margin: 2rem 0;
    color: #ff2837;
    font-size: 0.75rem !important;
    font-family: "Gotham Book"; }
    @media (min-width: 1024px) {
      section h1 + h1 {
        display: block !important;
        margin: 0 0 10px 0;
        transform: translate(-1.5rem, -1.38rem);
        float: right; } }
    section h1 + h1 * {
      display: inline !important;
      color: #161632;
      font-family: "Gotham";
      font-style: normal; }
  section p {
    font-size: 1.0625rem;
    letter-spacing: 0.35px;
    line-height: 1.5; }
  section img {
    max-width: 100%;
    height: auto; }
  section a {
    color: red;
    text-decoration: underline; }
  section input[type="checkbox"] {
    position: absolute;
    cursor: pointer;
    width: calc(100% - 4rem);
    height: 2rem;
    z-index: 1;
    opacity: 0;
    display: block !important; }
    section input[type="checkbox"]:hover + h1 {
      opacity: 0.6; }
  section input[type="checkbox"]:not(:checked) ~ * {
    display: none; }
  section input[type="checkbox"]:checked ~ h1:first-of-type:after {
    content: "⌝"; }

.intro {
  background: #ff2837;
  padding: 2rem;
  margin-bottom: 0.2rem;
  overflow: hidden;
  box-sizing: border-box;
  position: relative; }
  @media (min-width: 760px) {
    .intro {
      padding-right: 50%; } }
  @media (min-width: 1024px) {
    .intro:after {
      background: #fff;
      width: 40rem;
      height: 10rem;
      min-height: 200px;
      position: absolute;
      right: 0px;
      top: 0;
      content: "";
      display: block;
      transform: rotate(-45deg); } }
  .intro h1 {
    color: #fff;
    text-transform: uppercase;
    margin: 0px;
    padding: 0px;
    font-weight: normal;
    font-size: 2em; }
  .intro nav ul {
    margin: 1rem 0 0 0px;
    list-style-type: none;
    padding: 0px; }
    .intro nav ul li {
      margin: 0px;
      padding: 0px;
      color: #fff;
      padding: 0.5rem;
      font-size: 1.2rem;
      cursor: pointer;
      opacity: 0.8; }
      .intro nav ul li:hover {
        opacity: 1; }
      .intro nav ul li.active {
        opacity: 1;
        background: linear-gradient(to right, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%); }
        .intro nav ul li.active:hover {
          background: linear-gradient(to right, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 100%); }

.loginBox {
  width: 20rem;
  height: auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  padding: 2rem;
  margin: 4rem auto;
  border-radius: 0.5em; }
  .loginBox h1 {
    margin: 0px;
    padding: 0px;
    font-size: 1.5rem;
    margin-bottom: 0.5rem; }
  .loginBox p {
    margin: 0px;
    padding: 0px;
    font-size: 1rem;
    margin-bottom: 1rem;
    color: rgba(0, 0, 0, 0.5); }

.mainContainer {
  min-height: calc(100vh - 8rem);
  height: auto;
  padding-bottom: 1rem; }

footer {
  display: block;
  margin-top: 1rem;
  height: 2rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  color: #161632;
  text-align: right; }

.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #ff2837;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out; }

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    opacity: 0; } }

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0; } }

.details {
  position: relative;
  padding: 2rem 2rem 0 2rem;
  overflow: hidden;
  line-height: 2rem;
  margin-bottom: 0rem; }
  @media (min-width: 760px) {
    .details {
      float: left;
      width: 50%; } }
  .details h1 {
    color: #ff2837;
    font-size: 2rem;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 0.85rem; }
  .details h2 {
    font-size: 1.2rem;
    max-width: 700px;
    margin-bottom: 0.85rem;
    font-weight: normal; }
  .details h3 {
    font-size: 1.2rem; }
  .details p {
    font-size: 1rem; }
  .details a {
    color: #ff2837;
    display: block;
    text-decoration: underline; }
  .details ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    margin-bottom: 0.85rem; }
    .details ul li {
      margin-bottom: 0.45rem;
      font-size: 1rem; }
      .details ul li:nth-of-type(1) {
        padding-left: 1.8rem;
        background: url(./architect.svg) no-repeat 2px center;
        background-size: 1.2rem auto;
        height: 1.5rem;
        line-height: 1.7rem; }
        .details ul li:nth-of-type(1) strong {
          display: none; }
      .details ul li:nth-of-type(2) {
        padding-left: 1.8rem;
        background: url(./slack.svg) no-repeat center left;
        background-size: contain;
        height: 1.5rem;
        line-height: 1.7rem; }
        .details ul li:nth-of-type(2) strong {
          display: none; }

.subHeader {
  font-size: 1rem;
  color: #444;
  text-transform: uppercase;
  margin: 2rem 0 2rem 2rem;
  font-weight: bold;
  float: left; }

.searchbar {
  background: #f8f7f6;
  border: 0px;
  margin-left: 2rem;
  margin-top: 1rem;
  float: right;
  font-size: 1rem;
  padding: 0.5rem;
  outline: none !important;
  width: 300px; }
  .searchbar + div {
    margin-top: 1.5rem; }
  .searchbar:before {
    display: block;
    content: 🔍;
    font-size: 10000px;
    position: absolute;
    left: 0px;
    top: 0px; }

.noResults {
  clear: both;
  padding: 0px 0rem 2rem 2rem;
  margin: 0px; }

.roadmap {
  clear: both; }

.mainContainer {
  max-width: 1400px;
  margin: 0 auto; }
